/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 
    0px 0px 0px 1px #E0B4B4 inset, 
    0px 2px 4px 0px rgba(34, 36, 38, 0.12), 
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 0.9em;
}

.ui.vertical.menu .menu .menu .item {
  padding-left: 2em;
  font-size: 1em;
}

.ui.inverted.menu .item {
  /* color: #C21B17; */
}

.ui.inverted.menu .menu.header-menu a.item:hover {
  /* color: #C21B17; */
  color: #FFFFFF ;
}

.ui.inverted.menu .menu a.item:hover {
  /* color: #C21B17; */
  color: #20252B;
}

.ui.attached.inverted.header-primary-400.menu {
  background: #C21B17;
}

.ui.attached.inverted.sidebar-grey.menu, .ui.inverted.menu {
  background: #F6F6F6;
}

.ui.inverted.menu .active.item {
  background: #EBEBEB !important;
  border-left: 4px solid #C21B17;
  font-weight: 700 !important;
}

.ui.vertical.inverted.menu .menu .item{
  color: #20252B !important;
}

.ui.inverted.menu .link.item:hover,.ui.vertical.inverted.menu .item .menu .link.item:hover, .ui.vertical.inverted.menu .item .menu a.item:hover{
  color: #6F7072;
}

.ui.attached.inverted.sidebar-grey.menu {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox browser*/
  overflow-y: scroll; 
}

.ui.attached.inverted.sidebar-grey.menu::-webkit-scrollbar, .ui.inverted.menu::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}